"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookerEmailBookingInput = instanceOfBookerEmailBookingInput;
exports.BookerEmailBookingInputFromJSON = BookerEmailBookingInputFromJSON;
exports.BookerEmailBookingInputFromJSONTyped = BookerEmailBookingInputFromJSONTyped;
exports.BookerEmailBookingInputToJSON = BookerEmailBookingInputToJSON;
var BookerEmailBookingInputType_1 = require("./BookerEmailBookingInputType");
/**
 * Check if a given object implements the BookerEmailBookingInput interface.
 */
function instanceOfBookerEmailBookingInput(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function BookerEmailBookingInputFromJSON(json) {
    return BookerEmailBookingInputFromJSONTyped(json, false);
}
function BookerEmailBookingInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, BookerEmailBookingInputType_1.BookerEmailBookingInputTypeFromJSON)(json['type']),
    };
}
function BookerEmailBookingInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, BookerEmailBookingInputType_1.BookerEmailBookingInputTypeToJSON)(value.type),
    };
}
