"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancellationTimelinePolicyType = void 0;
exports.CancellationTimelinePolicyTypeFromJSON = CancellationTimelinePolicyTypeFromJSON;
exports.CancellationTimelinePolicyTypeFromJSONTyped = CancellationTimelinePolicyTypeFromJSONTyped;
exports.CancellationTimelinePolicyTypeToJSON = CancellationTimelinePolicyTypeToJSON;
/**
 *
 * @export
 */
exports.CancellationTimelinePolicyType = {
    NoRefund: 'noRefund',
    PartialRefund: 'partialRefund',
    FullRefund: 'fullRefund'
};
function CancellationTimelinePolicyTypeFromJSON(json) {
    return CancellationTimelinePolicyTypeFromJSONTyped(json, false);
}
function CancellationTimelinePolicyTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function CancellationTimelinePolicyTypeToJSON(value) {
    return value;
}
