"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookerEmailBookingChoice = instanceOfBookerEmailBookingChoice;
exports.BookerEmailBookingChoiceFromJSON = BookerEmailBookingChoiceFromJSON;
exports.BookerEmailBookingChoiceFromJSONTyped = BookerEmailBookingChoiceFromJSONTyped;
exports.BookerEmailBookingChoiceToJSON = BookerEmailBookingChoiceToJSON;
var runtime_1 = require("../runtime");
var BookerEmailBookingChoiceType_1 = require("./BookerEmailBookingChoiceType");
/**
 * Check if a given object implements the BookerEmailBookingChoice interface.
 */
function instanceOfBookerEmailBookingChoice(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function BookerEmailBookingChoiceFromJSON(json) {
    return BookerEmailBookingChoiceFromJSONTyped(json, false);
}
function BookerEmailBookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, BookerEmailBookingChoiceType_1.BookerEmailBookingChoiceTypeFromJSON)(json['type']),
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
    };
}
function BookerEmailBookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, BookerEmailBookingChoiceType_1.BookerEmailBookingChoiceTypeToJSON)(value.type),
        'email': value.email,
    };
}
