"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTrainSeatingOptionsBookingInput = instanceOfTrainSeatingOptionsBookingInput;
exports.TrainSeatingOptionsBookingInputFromJSON = TrainSeatingOptionsBookingInputFromJSON;
exports.TrainSeatingOptionsBookingInputFromJSONTyped = TrainSeatingOptionsBookingInputFromJSONTyped;
exports.TrainSeatingOptionsBookingInputToJSON = TrainSeatingOptionsBookingInputToJSON;
var TrainEachWayReservability_1 = require("./TrainEachWayReservability");
var TrainSeatingOptionsBookingInputType_1 = require("./TrainSeatingOptionsBookingInputType");
var TrainSeatingPreferenceGroups_1 = require("./TrainSeatingPreferenceGroups");
/**
 * Check if a given object implements the TrainSeatingOptionsBookingInput interface.
 */
function instanceOfTrainSeatingOptionsBookingInput(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "reservability" in value;
    isInstance = isInstance && "seatingPreferenceGroups" in value;
    return isInstance;
}
function TrainSeatingOptionsBookingInputFromJSON(json) {
    return TrainSeatingOptionsBookingInputFromJSONTyped(json, false);
}
function TrainSeatingOptionsBookingInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TrainSeatingOptionsBookingInputType_1.TrainSeatingOptionsBookingInputTypeFromJSON)(json['type']),
        'reservability': (0, TrainEachWayReservability_1.TrainEachWayReservabilityFromJSON)(json['reservability']),
        'seatingPreferenceGroups': (0, TrainSeatingPreferenceGroups_1.TrainSeatingPreferenceGroupsFromJSON)(json['seatingPreferenceGroups']),
    };
}
function TrainSeatingOptionsBookingInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TrainSeatingOptionsBookingInputType_1.TrainSeatingOptionsBookingInputTypeToJSON)(value.type),
        'reservability': (0, TrainEachWayReservability_1.TrainEachWayReservabilityToJSON)(value.reservability),
        'seatingPreferenceGroups': (0, TrainSeatingPreferenceGroups_1.TrainSeatingPreferenceGroupsToJSON)(value.seatingPreferenceGroups),
    };
}
