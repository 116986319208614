"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStayLoyaltyProgramme = instanceOfStayLoyaltyProgramme;
exports.StayLoyaltyProgrammeFromJSON = StayLoyaltyProgrammeFromJSON;
exports.StayLoyaltyProgrammeFromJSONTyped = StayLoyaltyProgrammeFromJSONTyped;
exports.StayLoyaltyProgrammeToJSON = StayLoyaltyProgrammeToJSON;
var StayLoyaltyProgrammeType_1 = require("./StayLoyaltyProgrammeType");
/**
 * Check if a given object implements the StayLoyaltyProgramme interface.
 */
function instanceOfStayLoyaltyProgramme(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "logoUrl" in value;
    return isInstance;
}
function StayLoyaltyProgrammeFromJSON(json) {
    return StayLoyaltyProgrammeFromJSONTyped(json, false);
}
function StayLoyaltyProgrammeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, StayLoyaltyProgrammeType_1.StayLoyaltyProgrammeTypeFromJSON)(json['type']),
        'name': json['name'],
        'reference': json['reference'],
        'logoUrl': json['logoUrl'],
    };
}
function StayLoyaltyProgrammeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, StayLoyaltyProgrammeType_1.StayLoyaltyProgrammeTypeToJSON)(value.type),
        'name': value.name,
        'reference': value.reference,
        'logoUrl': value.logoUrl,
    };
}
