"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegalNoticeFromJSON = LegalNoticeFromJSON;
exports.LegalNoticeFromJSONTyped = LegalNoticeFromJSONTyped;
exports.LegalNoticeToJSON = LegalNoticeToJSON;
var AirlineLegalNotice_1 = require("./AirlineLegalNotice");
var NationalRailLegalNotice_1 = require("./NationalRailLegalNotice");
var StayLegalNotice_1 = require("./StayLegalNotice");
function LegalNoticeFromJSON(json) {
    return LegalNoticeFromJSONTyped(json, false);
}
function LegalNoticeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'airline':
            return __assign(__assign({}, (0, AirlineLegalNotice_1.AirlineLegalNoticeFromJSONTyped)(json, true)), { type: 'airline' });
        case 'nationalRail':
            return __assign(__assign({}, (0, NationalRailLegalNotice_1.NationalRailLegalNoticeFromJSONTyped)(json, true)), { type: 'nationalRail' });
        case 'stay':
            return __assign(__assign({}, (0, StayLegalNotice_1.StayLegalNoticeFromJSONTyped)(json, true)), { type: 'stay' });
        default:
            throw new Error("No variant of LegalNotice exists with 'type=".concat(json['type'], "'"));
    }
}
function LegalNoticeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'airline':
            return (0, AirlineLegalNotice_1.AirlineLegalNoticeToJSON)(value);
        case 'nationalRail':
            return (0, NationalRailLegalNotice_1.NationalRailLegalNoticeToJSON)(value);
        case 'stay':
            return (0, StayLegalNotice_1.StayLegalNoticeToJSON)(value);
        default:
            throw new Error("No variant of LegalNotice exists with 'type=".concat(value['type'], "'"));
    }
}
