"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTicketCollectionTypeBookingInput = instanceOfTicketCollectionTypeBookingInput;
exports.TicketCollectionTypeBookingInputFromJSON = TicketCollectionTypeBookingInputFromJSON;
exports.TicketCollectionTypeBookingInputFromJSONTyped = TicketCollectionTypeBookingInputFromJSONTyped;
exports.TicketCollectionTypeBookingInputToJSON = TicketCollectionTypeBookingInputToJSON;
var TicketCollectionType_1 = require("./TicketCollectionType");
var TicketCollectionTypeBookingInputType_1 = require("./TicketCollectionTypeBookingInputType");
/**
 * Check if a given object implements the TicketCollectionTypeBookingInput interface.
 */
function instanceOfTicketCollectionTypeBookingInput(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "collectionTypes" in value;
    return isInstance;
}
function TicketCollectionTypeBookingInputFromJSON(json) {
    return TicketCollectionTypeBookingInputFromJSONTyped(json, false);
}
function TicketCollectionTypeBookingInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TicketCollectionTypeBookingInputType_1.TicketCollectionTypeBookingInputTypeFromJSON)(json['type']),
        'collectionTypes': (json['collectionTypes'].map(TicketCollectionType_1.TicketCollectionTypeFromJSON)),
    };
}
function TicketCollectionTypeBookingInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TicketCollectionTypeBookingInputType_1.TicketCollectionTypeBookingInputTypeToJSON)(value.type),
        'collectionTypes': (value.collectionTypes.map(TicketCollectionType_1.TicketCollectionTypeToJSON)),
    };
}
