"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyBookingProductType = void 0;
exports.JourneyBookingProductTypeFromJSON = JourneyBookingProductTypeFromJSON;
exports.JourneyBookingProductTypeFromJSONTyped = JourneyBookingProductTypeFromJSONTyped;
exports.JourneyBookingProductTypeToJSON = JourneyBookingProductTypeToJSON;
/**
 *
 * @export
 */
exports.JourneyBookingProductType = {
    Journey: 'journey'
};
function JourneyBookingProductTypeFromJSON(json) {
    return JourneyBookingProductTypeFromJSONTyped(json, false);
}
function JourneyBookingProductTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function JourneyBookingProductTypeToJSON(value) {
    return value;
}
