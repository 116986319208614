"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TravellerStayLoyaltyProgrammeType = void 0;
exports.TravellerStayLoyaltyProgrammeTypeFromJSON = TravellerStayLoyaltyProgrammeTypeFromJSON;
exports.TravellerStayLoyaltyProgrammeTypeFromJSONTyped = TravellerStayLoyaltyProgrammeTypeFromJSONTyped;
exports.TravellerStayLoyaltyProgrammeTypeToJSON = TravellerStayLoyaltyProgrammeTypeToJSON;
/**
 *
 * @export
 */
exports.TravellerStayLoyaltyProgrammeType = {
    StayLoyaltyProgramme: 'stayLoyaltyProgramme'
};
function TravellerStayLoyaltyProgrammeTypeFromJSON(json) {
    return TravellerStayLoyaltyProgrammeTypeFromJSONTyped(json, false);
}
function TravellerStayLoyaltyProgrammeTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TravellerStayLoyaltyProgrammeTypeToJSON(value) {
    return value;
}
