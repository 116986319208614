"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellerStayLoyaltyProgramme = instanceOfTravellerStayLoyaltyProgramme;
exports.TravellerStayLoyaltyProgrammeFromJSON = TravellerStayLoyaltyProgrammeFromJSON;
exports.TravellerStayLoyaltyProgrammeFromJSONTyped = TravellerStayLoyaltyProgrammeFromJSONTyped;
exports.TravellerStayLoyaltyProgrammeToJSON = TravellerStayLoyaltyProgrammeToJSON;
var TravellerStayLoyaltyProgrammeType_1 = require("./TravellerStayLoyaltyProgrammeType");
/**
 * Check if a given object implements the TravellerStayLoyaltyProgramme interface.
 */
function instanceOfTravellerStayLoyaltyProgramme(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "accountNumber" in value;
    return isInstance;
}
function TravellerStayLoyaltyProgrammeFromJSON(json) {
    return TravellerStayLoyaltyProgrammeFromJSONTyped(json, false);
}
function TravellerStayLoyaltyProgrammeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellerStayLoyaltyProgrammeType_1.TravellerStayLoyaltyProgrammeTypeFromJSON)(json['type']),
        'reference': json['reference'],
        'accountNumber': json['accountNumber'],
    };
}
function TravellerStayLoyaltyProgrammeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellerStayLoyaltyProgrammeType_1.TravellerStayLoyaltyProgrammeTypeToJSON)(value.type),
        'reference': value.reference,
        'accountNumber': value.accountNumber,
    };
}
