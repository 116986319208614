"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DuffelFlightsFeeLocation = void 0;
exports.DuffelFlightsFeeLocationFromJSON = DuffelFlightsFeeLocationFromJSON;
exports.DuffelFlightsFeeLocationFromJSONTyped = DuffelFlightsFeeLocationFromJSONTyped;
exports.DuffelFlightsFeeLocationToJSON = DuffelFlightsFeeLocationToJSON;
/**
 *
 * @export
 */
exports.DuffelFlightsFeeLocation = {
    Fare: 'fare',
    BookingFee: 'bookingFee',
    NotIncluded: 'notIncluded'
};
function DuffelFlightsFeeLocationFromJSON(json) {
    return DuffelFlightsFeeLocationFromJSONTyped(json, false);
}
function DuffelFlightsFeeLocationFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function DuffelFlightsFeeLocationToJSON(value) {
    return value;
}
