"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStayLegalNotice = instanceOfStayLegalNotice;
exports.StayLegalNoticeFromJSON = StayLegalNoticeFromJSON;
exports.StayLegalNoticeFromJSONTyped = StayLegalNoticeFromJSONTyped;
exports.StayLegalNoticeToJSON = StayLegalNoticeToJSON;
var StayLegalNoticeType_1 = require("./StayLegalNoticeType");
/**
 * Check if a given object implements the StayLegalNotice interface.
 */
function instanceOfStayLegalNotice(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function StayLegalNoticeFromJSON(json) {
    return StayLegalNoticeFromJSONTyped(json, false);
}
function StayLegalNoticeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, StayLegalNoticeType_1.StayLegalNoticeTypeFromJSON)(json['type']),
    };
}
function StayLegalNoticeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, StayLegalNoticeType_1.StayLegalNoticeTypeToJSON)(value.type),
    };
}
