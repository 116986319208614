"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTrainSeatingOptionsBookingChoice = instanceOfTrainSeatingOptionsBookingChoice;
exports.TrainSeatingOptionsBookingChoiceFromJSON = TrainSeatingOptionsBookingChoiceFromJSON;
exports.TrainSeatingOptionsBookingChoiceFromJSONTyped = TrainSeatingOptionsBookingChoiceFromJSONTyped;
exports.TrainSeatingOptionsBookingChoiceToJSON = TrainSeatingOptionsBookingChoiceToJSON;
var runtime_1 = require("../runtime");
var TrainSeatingOptionsBookingChoiceType_1 = require("./TrainSeatingOptionsBookingChoiceType");
/**
 * Check if a given object implements the TrainSeatingOptionsBookingChoice interface.
 */
function instanceOfTrainSeatingOptionsBookingChoice(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "reserveOutwardSeat" in value;
    isInstance = isInstance && "reserveInwardSeat" in value;
    return isInstance;
}
function TrainSeatingOptionsBookingChoiceFromJSON(json) {
    return TrainSeatingOptionsBookingChoiceFromJSONTyped(json, false);
}
function TrainSeatingOptionsBookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TrainSeatingOptionsBookingChoiceType_1.TrainSeatingOptionsBookingChoiceTypeFromJSON)(json['type']),
        'reserveOutwardSeat': json['reserveOutwardSeat'],
        'reserveInwardSeat': json['reserveInwardSeat'],
        'positionPreferenceId': !(0, runtime_1.exists)(json, 'positionPreferenceId') ? undefined : json['positionPreferenceId'],
        'facilitySeatingPreferenceIds': !(0, runtime_1.exists)(json, 'facilitySeatingPreferenceIds') ? undefined : json['facilitySeatingPreferenceIds'],
        'directionPreferenceId': !(0, runtime_1.exists)(json, 'directionPreferenceId') ? undefined : json['directionPreferenceId'],
        'carriageTypePreferenceId': !(0, runtime_1.exists)(json, 'carriageTypePreferenceId') ? undefined : json['carriageTypePreferenceId'],
        'seatTypePreferenceId': !(0, runtime_1.exists)(json, 'seatTypePreferenceId') ? undefined : json['seatTypePreferenceId'],
        'deckPreferenceId': !(0, runtime_1.exists)(json, 'deckPreferenceId') ? undefined : json['deckPreferenceId'],
    };
}
function TrainSeatingOptionsBookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TrainSeatingOptionsBookingChoiceType_1.TrainSeatingOptionsBookingChoiceTypeToJSON)(value.type),
        'reserveOutwardSeat': value.reserveOutwardSeat,
        'reserveInwardSeat': value.reserveInwardSeat,
        'positionPreferenceId': value.positionPreferenceId,
        'facilitySeatingPreferenceIds': value.facilitySeatingPreferenceIds,
        'directionPreferenceId': value.directionPreferenceId,
        'carriageTypePreferenceId': value.carriageTypePreferenceId,
        'seatTypePreferenceId': value.seatTypePreferenceId,
        'deckPreferenceId': value.deckPreferenceId,
    };
}
