"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vendor = void 0;
exports.VendorFromJSON = VendorFromJSON;
exports.VendorFromJSONTyped = VendorFromJSONTyped;
exports.VendorToJSON = VendorToJSON;
/**
 * Third party vendor who is responsible for placing an order with rail, airline, hotel, etc. Also supports RouteZero for composite bookings.
 * @export
 */
exports.Vendor = {
    RouteZero: 'routeZero',
    Trainline: 'trainline',
    DuffelFlights: 'duffelFlights',
    DuffelStays: 'duffelStays'
};
function VendorFromJSON(json) {
    return VendorFromJSONTyped(json, false);
}
function VendorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function VendorToJSON(value) {
    return value;
}
