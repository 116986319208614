"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfJourneyBookingProduct = instanceOfJourneyBookingProduct;
exports.JourneyBookingProductFromJSON = JourneyBookingProductFromJSON;
exports.JourneyBookingProductFromJSONTyped = JourneyBookingProductFromJSONTyped;
exports.JourneyBookingProductToJSON = JourneyBookingProductToJSON;
var BookingFares_1 = require("./BookingFares");
var BookingJourneys_1 = require("./BookingJourneys");
var JourneyBookingProductType_1 = require("./JourneyBookingProductType");
/**
 * Check if a given object implements the JourneyBookingProduct interface.
 */
function instanceOfJourneyBookingProduct(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "journeys" in value;
    isInstance = isInstance && "fares" in value;
    return isInstance;
}
function JourneyBookingProductFromJSON(json) {
    return JourneyBookingProductFromJSONTyped(json, false);
}
function JourneyBookingProductFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, JourneyBookingProductType_1.JourneyBookingProductTypeFromJSON)(json['type']),
        'journeys': (0, BookingJourneys_1.BookingJourneysFromJSON)(json['journeys']),
        'fares': (0, BookingFares_1.BookingFaresFromJSON)(json['fares']),
    };
}
function JourneyBookingProductToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, JourneyBookingProductType_1.JourneyBookingProductTypeToJSON)(value.type),
        'journeys': (0, BookingJourneys_1.BookingJourneysToJSON)(value.journeys),
        'fares': (0, BookingFares_1.BookingFaresToJSON)(value.fares),
    };
}
