"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StayLegalNoticeType = void 0;
exports.StayLegalNoticeTypeFromJSON = StayLegalNoticeTypeFromJSON;
exports.StayLegalNoticeTypeFromJSONTyped = StayLegalNoticeTypeFromJSONTyped;
exports.StayLegalNoticeTypeToJSON = StayLegalNoticeTypeToJSON;
/**
 *
 * @export
 */
exports.StayLegalNoticeType = {
    Stay: 'stay'
};
function StayLegalNoticeTypeFromJSON(json) {
    return StayLegalNoticeTypeFromJSONTyped(json, false);
}
function StayLegalNoticeTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function StayLegalNoticeTypeToJSON(value) {
    return value;
}
