"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPassengerDetailsBookingChoice = instanceOfPassengerDetailsBookingChoice;
exports.PassengerDetailsBookingChoiceFromJSON = PassengerDetailsBookingChoiceFromJSON;
exports.PassengerDetailsBookingChoiceFromJSONTyped = PassengerDetailsBookingChoiceFromJSONTyped;
exports.PassengerDetailsBookingChoiceToJSON = PassengerDetailsBookingChoiceToJSON;
var FlightPassengerDetails_1 = require("./FlightPassengerDetails");
var PassengerDetailsBookingChoiceType_1 = require("./PassengerDetailsBookingChoiceType");
/**
 * Check if a given object implements the PassengerDetailsBookingChoice interface.
 */
function instanceOfPassengerDetailsBookingChoice(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "details" in value;
    return isInstance;
}
function PassengerDetailsBookingChoiceFromJSON(json) {
    return PassengerDetailsBookingChoiceFromJSONTyped(json, false);
}
function PassengerDetailsBookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PassengerDetailsBookingChoiceType_1.PassengerDetailsBookingChoiceTypeFromJSON)(json['type']),
        'details': (json['details'].map(FlightPassengerDetails_1.FlightPassengerDetailsFromJSON)),
    };
}
function PassengerDetailsBookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PassengerDetailsBookingChoiceType_1.PassengerDetailsBookingChoiceTypeToJSON)(value.type),
        'details': (value.details.map(FlightPassengerDetails_1.FlightPassengerDetailsToJSON)),
    };
}
